var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hiddenHarvesterCount)?_c('Banner',{attrs:{"color":"info","label":_vm.t('cluster.harvester.clusterWarning', {count: _vm.hiddenHarvesterCount} )}}):_vm._e(),_vm._v(" "),_c('Masthead',{attrs:{"schema":_vm.schema,"resource":_vm.resource,"create-location":_vm.createLocation,"component-testid":"cluster-manager-list"}},[(_vm.canImport)?_c('template',{slot:"extraActions"},[_c('n-link',{staticClass:"btn role-primary",attrs:{"to":_vm.importLocation,"data-testid":"cluster-manager-list-import"}},[_vm._v("\n        "+_vm._s(_vm.t('cluster.importAction'))+"\n      ")])],1):_vm._e()],2),_vm._v(" "),_c('ResourceTable',{attrs:{"schema":_vm.schema,"rows":_vm.rows,"namespaced":false,"loading":_vm.$fetchState.pending},scopedSlots:_vm._u([{key:"cell:state",fn:function(ref){
var row = ref.row;
return [_c('BadgeState',{attrs:{"color":row.stateBackground,"label":row.stateDisplay}})]}},{key:"sub-row",fn:function(ref){
var fullColspan = ref.fullColspan;
var row = ref.row;
var keyField = ref.keyField;
var componentTestid = ref.componentTestid;
var i = ref.i;
var onRowMouseEnter = ref.onRowMouseEnter;
var onRowMouseLeave = ref.onRowMouseLeave;
return [(row.stateDescription)?_c('tr',{key:row[keyField] + '-description',staticClass:"state-description sub-row",attrs:{"data-testid":componentTestid + '-' + i + '-row-description'},on:{"mouseenter":onRowMouseEnter,"mouseleave":onRowMouseLeave}},[_c('td',[_vm._v(" ")]),_vm._v(" "),_c('td',{class:{ 'text-error' : row.stateObj.error },attrs:{"colspan":fullColspan - 1}},[_vm._v("\n          "+_vm._s(row.stateDescription)+"\n        ")])]):_vm._e()]}},{key:"cell:summary",fn:function(ref){
var row = ref.row;
return [(!row.stateParts.length)?_c('span',[_vm._v(_vm._s(row.nodes.length))]):_vm._e()]}},{key:"cell:explorer",fn:function(ref){
var row = ref.row;
return [(row.mgmt && row.mgmt.isHarvester)?_c('span'):(row.mgmt && row.mgmt.isReady)?_c('n-link',{staticClass:"btn btn-sm role-secondary",attrs:{"data-testid":"cluster-manager-list-explore-management","to":{name: 'c-cluster', params: {cluster: row.mgmt.id}}}},[_vm._v("\n        "+_vm._s(_vm.t('cluster.exploreHarvester'))+"\n      ")]):_c('button',{staticClass:"btn btn-sm role-secondary",attrs:{"data-testid":"cluster-manager-list-explore","disabled":true}},[_vm._v("\n        "+_vm._s(_vm.t('cluster.explore'))+"\n      ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }